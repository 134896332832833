import Layout from "components/comachicart/Layout";
import Maintenance from "components/comachicart/Maintenance";
import SEO from "components/seo";
import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "@reach/router";

import { news_endpoint } from "../../constants";

const ReleacePage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const newsId = queryParams.get("id");

  const fetchData = useCallback(async () => {
    if (!newsId) {
      setError("パラメータ 'id' が存在しません");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(news_endpoint, { cache: "no-store" });
      if (!response.ok) {
        throw new Error(`HTTPエラー: ${response.status}`);
      }
      const jsonData = await response.json();
      if (!jsonData[newsId]) {
        throw new Error("指定されたIDのデータが見つかりません");
      }
      setData(jsonData[newsId]);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [newsId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <Layout>
        <SEO title="comachicart" path="comachicart/news" />
        <p>読み込み中...</p>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <SEO title="comachicart" path="comachicart/news" />
        <p>エラー: {error}</p>
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO title="comachicart" path="comachicart/news" />
      {data && <Maintenance data={data} />}
    </Layout>
  );
};

export default ReleacePage;
